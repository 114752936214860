<template>
  <div class="card" :class="width && `card--${width}`">
    <div class="card__top" v-if="data.logo">
      <img class="card__logo" :src="data.logo" :alt="data.name"/>
      <p>{{data.name}}</p>
    </div>
    <div class="card__content">
      <h3 v-if="data.header">{{data.header}}</h3>
      <p v-if="data.description">{{data.description}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    data: Object,
    width: String
  }
}
</script>

<style scoped lang="scss">
@import "../styles";

.card {
  background-color: $callout;
  border-radius: 4pt;
  padding: 1em 2em;

  &--1-3 {
    @include md {
      width: 33%;
    }
  }

  h3 {
    margin-top: 0;
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    width: 3em;
    height: 3em;
    border-radius: 50%;
  }

  &__content {
    text-align: left;
  }
}
</style>
