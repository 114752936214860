<template>
  <div class="seemore">
    <div class="seemore__label" @click="toggleSeeMore">
      <span>{{active ? activeLabel : label}}</span>
      <v-icon name="chevron-up" v-if="active"></v-icon>
      <v-icon name="chevron-down" v-else></v-icon>
    </div>
    <transition name="slide">
      <div class="seemore__container" v-if="active" :class="active && 'active'"><slot /></div>
    </transition>
  </div>
</template>

<script>
import 'vue-awesome/icons/chevron-up'
import 'vue-awesome/icons/chevron-down'

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    offLabel: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      active: true
    }
  },
  name: "SeeMore",
  methods: {
    toggleSeeMore() {
      this.active = !this.active
    },
  },
  computed: {
    activeLabel() {
      return this.offLabel ? this.offLabel : this.label
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";

.seemore {
  width: 100%;
  padding-top: 1em;
  padding-bottom: 2em;

  &__label {
    padding-bottom: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;

    &:hover {
      span {
        color: #000;
        text-decoration: underline;
      }
    }

    span {
      cursor: pointer;
      text-align: center;
      transition: all .3s ease-in-out;
    }
  }
}

.slide-enter-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
