<template>
  <div class="app">
    <!--<NavButton side="left" to="erfaring" v-show="currentSide !== 'left'" />-->
    <!--<NavButton side="right" to="kontakt" v-show="currentSide !== 'right'" />-->
    <div class="app__content">
      <!-- transition effect to router -->
      <transition
          :name="transitionName"
          mode="out-in"
          @beforeLeave="beforeLeave"
          @enter="enter"
          @afterEnter="afterEnter"
      >
        <!-- route outlet -->
        <!-- component matched by the route will render here -->
        <router-view @openModal="openModal"></router-view>
      </transition>
      <div class="footer">
        <p>hverdag v/Kris Lianee Maini-Thorsen</p>
        <p>Ring: <a href="tel:+4531326052">31326052</a></p>
        <p>Skriv: <a href="mailto:kris@hverdag.xyz">kris@hverdag.xyz</a></p>
        <p>CVR 42639532</p>
      </div>
    </div>
    <Overlay v-show="showModal" @close="closeModal" content=""></Overlay>
  </div>
</template>

<script>
//import NavButton from '@/components/NavButton'
import Overlay from '@/components/Overlay'

const DEFAULT_TRANSITION = 'fade'

export default {
  name: 'App',
  components: {
    //NavButton,
    Overlay
  },
  data() {
    return {
      prevHeight: 0,
      currentSide: '',
      transitionName: DEFAULT_TRANSITION,
      showModal: false,
      modalContent: 'pp',
    }
  },
  created() {
    this.currentSide = this.$router.currentRoute.meta.side
    this.$router.beforeEach((to, from, next) => {
        let transitionName = to.meta.transitionName
        this.currentSide = to.meta.side

        this.transitionName = transitionName || DEFAULT_TRANSITION;

       next();
    });
  },
  methods: {
    openModal(content) {
      if (this.showModal) return
      this.modalContent = content
      this.showModal = true
      const body = document.body
      body.classList.add('noscroll')
    },
    closeModal() {
      this.showModal = false
      const body = document.body
      body.classList.remove('noscroll')
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
  },
}
</script>

<style lang="scss">
@import "styles";

.app {

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer {
      padding-top: 2em;
      padding-bottom: 2em;

      p {
        margin: 0;
      }
    }
  }
}
.route {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
