<template>
  <div class="route">
    <Hero :data="hero" />
    <Container
        v-for="(container, index) in containers"
        v-bind:key="index"
        :data="container"
        class="container"
        @openModal="$emit('openModal', 'pp')" />
  </div>
</template>

<script>
import Container from '@/components/container'
import Hero from '@/components/Hero'

export default {
  components: { Container, Hero },
  name: 'middle',
  data() {
    return {
      hero: {
        p: 'Jeg hedder Kris og er en alsidig udvikler med passion for web og stor erfaring med React, Vue, typescript samt app udvikling med React Native og Expo. Jeg har arbejdet i store scrum hold og små tværfaglige teams. Jeg er udadvendt, smilende og god til kundekontakt.'
      },
      containers: [
        {
          h2: 'Jeg kan hjælpe jer i mål!',
          html: '<h3>Konsulentarbejde</h3>' +
              '<p>Mangler I en fast tilknyttet frontendudvikler til jeres organisation i en periode, er jeg åben for et samarbejde. Jeg har masser af erfaring, som din organisation kan trække på og få værdi af. Lad os tage en uformel snak om behovene og finde ud af sammen, hvordan og hvor et samarbejde vil give jer mest værdi.</p>',
          bg: true,
          cta: {
            label: 'kontakt mig',
            link: 'kontakt',
            extra: {
              type: 'fade-down-form',
              data: ''
            }
          },
          align: 'left'
        },
        {
          h2: 'Et godt match',
          html: '<h3>Hverdag</h3>' +
              '<p>Jeg er nysgerrig af natur - nysgerrig på mennesker, natur og ikke mindst teknologi.' +
              ' Jeg har opbygget en stor glæde ved at udvikle velfungerende, solide og ikke mindst værdiskabende digitale løsninger.' +
              ' Gennem min karriere har jeg udviklet en stærk interesse i frontend udvikling.</p>',
          bg: false,
          cta: {
            label: 'det kan jeg',
            link: 'erfaring',
            extra: {
              type: 'fade-down-list',
              data: ['HTML', 'Sass', 'Typescript', 'Vue', 'React', 'UX', 'Tailwind', 'Wordpress', 'WooCommerce', 'Storyblok', 'Contentful', 'SSR', 'Netlify']
            }
          },
          align: 'left'
        },
        {
          h2: 'X, Y og Z?',
          html: '<h3>en landing side til din <span class="highlight">virksomhed</span>, <br>en personlig <span class="highlight">blog</span> <br>eller professionel <span class="highlight">portefølje</span></h3>' +
              '<p>Uanset dine behov er mit job at skabe værdi for dig eller din virksomhed.</p>' +
              '<p>Udfyld formularen hvis du har brug for</p>' +
              '<ul><li>at generere leads</li><li>et responsivt design</li><li>at have styr på SEO</li><li>løbende rådgivning</li><li>et fleksibelt CMS</li><li>overskuelige priser</li></ul>' +
              '<p>Med priser fra 5000 kr. kan jeg hurtigt levere en simpel side til dig. (Se et eksempel <a href="https://sleepy-neumann-b2583d.netlify.app" target="_blank">her</a>.)</p>' +
              '<p>Har du brug for flere funktioner så kontakt mig for en uforpligtende snak.</p>',
          cards: [
            {description: 'Din virksomhedsidentitet skal være i fokus'},
            {description: 'Dine ideer og tanker har betydning'},
            {description: 'Dit arbejde har værdi'}
          ],
          bg: true,
          align: 'left'
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../styles";

</style>
