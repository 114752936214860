<template>
  <div class="hero">
    <div class="hero__content">
      <div class="hero__start">
        <h1 class="sentence">
          FREELANCE<br>
          <span class="words rotate">
          <span>FRONTEND</span>
          <span>VUE</span>
          <span>REACT</span>
          <span>WEB</span>
          <span>APP</span>
          <span>JAVASCRIPT</span>
        </span>
          <br>UDVIKLER
        </h1>
        <p>{{data.p}}</p>
      </div>
      <div class="hero__end">
        <div class="hero__profile">
          <img src="../assets/me.png" alt="kris">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
  props: {
    data: Object
  }
}
</script>

<style lang="scss" scoped>
@import "../styles";

.hero {
  //height: 80vh;

  @include md {
    padding-top: 3em;
    padding-bottom: 3em;
    //height: 30vh;
  }

  &__content {
    width: 70vw;
    margin-left: auto;
    margin-right: auto;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;

    @include md {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
    }
  }

  &__start {
    h1, p {
      text-align: left;
    }
  }

  .sentence {
    line-height: 3rem;
    .words {
      display: inline;
      text-indent: 0;

      span:not(.words) {
        position: absolute;
        opacity: 0;
        overflow: hidden;
        font-weight: inherit;

        &:nth-child(1) {
          color: #a8b94b;
        }
        &:nth-child(2) {
          color: #FF6E00;
          -webkit-animation-delay: 3s;
          animation-delay: 3s;
        }
        &:nth-child(3) {
          color: #532250;
          -webkit-animation-delay: 6s;
          animation-delay: 6s;
        }
        &:nth-child(4) {
          color: #c26219;
          -webkit-animation-delay: 9s;
          animation-delay: 9s;
        }
        &:nth-child(5) {
          color: #c5481b;
          -webkit-animation-delay: 12s;
          animation-delay: 12s;
        }
        &:nth-child(6) {
          color: #281f62;
          -webkit-animation-delay: 15s;
          animation-delay: 15s;
        }
      }
    }
    .rotate span {
      -webkit-animation:  rotateWord 18s linear infinite;
      animation:  rotateWord 18s linear infinite;
    }
  }

  @-webkit-keyframes rotateWord {
    0% { opacity: 0; }
    2% { opacity: 0; -webkit-transform: translateY(-30px); }
    5% { opacity: 1; -webkit-transform: translateY(0px);}
    25% { opacity: 1; -webkit-transform: translateY(0px); }
    27% { opacity: 0; -webkit-transform: translateY(30px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
  }
  @-ms-keyframes rotateWord {
    0% { opacity: 0; }
    2% { opacity: 0; -ms-transform: translateY(-30px); }
    5% { opacity: 1; -ms-transform: translateY(0px);}
    17% { opacity: 1; -ms-transform: translateY(0px); }
    20% { opacity: 0; -ms-transform: translateY(30px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
  }
  @keyframes rotateWord {
    0% { opacity: 0; }
    2% { opacity: 0; -webkit-transform: translateY(-30px); transform: translateY(-30px); }
    5% { opacity: 1; -webkit-transform: translateY(0px); transform: translateY(0px);}
    17% { opacity: 1; -webkit-transform: translateY(0px); transform: translateY(0px); }
    20% { opacity: 0; -webkit-transform: translateY(30px); transform: translateY(30px); }
    80% { opacity: 0; }
    100% { opacity: 0; }
  }

  &__end {

  }

  &__profile {
    max-width: 20em;
    max-height: 20em;
    width: auto;
    height: auto;
    margin-left: auto;
    margin-right: auto;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

  }
}
</style>
