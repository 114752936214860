<template>
  <div class="container"
      :class="data.bg ? `container--bg container--${data.align}` : `container--${data.align}`">
    <div class="container__content">

      <div v-if="data.h2" class="container__header">
        <h2>{{data.h2}}</h2>
      </div>

      <div v-if="data.cards" class="responsive-row">
        <Card v-for="(card, index) in data.cards" v-bind:key="index" :data="card" width="1-3"></Card>
      </div>

      <div v-if="data.html" v-html="data.html" class="container__html" />

      <div class="container__form" v-if="data.cta && data.cta.extra.type === 'fade-down-form'">
        <form name="xyz-lead" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
          <input type="hidden" name="form-name" value="xyz-lead" />
          <label>
            <span>Email</span>
            <input type="email" name="email" v-model="email">
          </label>
          <label>
            <span>Navn</span>
            <input type="text" name="name" v-model="name">
          </label>
          <label>
            <span>Besked</span>
            <textarea v-model="message" name="message"></textarea>
          </label>

            <p>
              <label class="label hover">
                  <a @click="$emit('openModal', 'pp')">*Jeg har læst og forstået privatlivspolitikken</a>
              </label>
            </p>
          <!--<section class="slider-checkbox">
            <input type="checkbox" id="pp" v-model="ppAccepted" />
            <label class="label" for="pp">
              *Jeg har læst og forstået <span><a @click="$emit('openModal', 'pp')">privatlivspolitikken</a></span>
            </label>
          </section>-->
          <button type="submit">Send</button>
        </form>
      </div>

      <SeeMore :label="data.cta.label" off-label="luk" v-if="data.cta && data.cta.extra && data.cta.extra.type === 'fade-down-list'">
        <div class="fade-down-list">
          <div class="list-item" v-for="(item, index) in data.cta.extra.data" v-bind:key="index">
            {{item}}
          </div>
        </div>
      </SeeMore>

    </div>
  </div>
</template>

<script>
import Card from '@/components/Card'
//import CTA from '@/components/CTA'
import SeeMore from '@/components/SeeMore'

export default {
  components: { Card, SeeMore },
  props: {data: Object},
  name: "container",
  data() {
    return {
      ctaExtraVisible: false,
      prevHeight: 0,
      // form variables
      ppAccepted: false,
      email: '',
      name: '',
      message: '',
    }
  },
  methods: {
    ctaClick() {
      this.ctaExtraVisible = !this.ctaExtraVisible
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      }, 250);
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../styles";

.container {
  width: 100%;
  padding-top: 3em;
  padding-bottom: 3em;

  &--bg {
    background-color: $secondary-bg;
  }

  &--right {
    .container__content {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include md {
        .container__header, {
          align-self: flex-end;
        }
        .cta {
          align-self: flex-start;
        }
      }
    }

    .container__html {

      @include md {
        //text-align: right;
      }
    }
  }

  &--left {
    .container__content {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include md {
        align-items: flex-start;
      }
    }

    .container__html {

      @include md {
        text-align: left;
      }
    }
  }

  &__content {
    margin-left: auto;
    margin-right: auto;
    max-width: 70vw;
    //transition: height .3s ease-in-out;
  }

  &__header {
    //transform: translateY(-2em);
    //background-color: $tertiary;
    //padding: 1em 2em;
    //width: 80%;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //border-radius: 4pt;

    @include md {
      //width: 40%;
    }
  }

  &__html {
    width: 100%;
    text-align: left;
  }

  &__extra {
    padding-top: 1em;
    padding-bottom: 1em;
    width: 100%;
    transition: height .4s ease-in-out;
  }

  &__form {
    align-self: center;
  }

  .grow-enter-active {
    animation: grow-in .5s;
  }
  .grow-leave-active {
    animation: grow-in .5s reverse;
  }
  @keyframes grow-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  .fade-down-list {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 5px;
    grid-row-gap: 5px;

    .list-item {
      background-color: $dark;
      border-radius: 4pt;
      padding: 5px 10px;
      color: $secondary-text;
    }
  }
}
</style>
