import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
//import { rtdbPlugin } from 'vuefire'
//import 'firebase/firestore'
import Icon from 'vue-awesome/components/Icon'
import middle from '@/components/routes/middle'

Vue.config.productionTip = false

Vue.use(VueRouter)
//Vue.use(rtdbPlugin)
Vue.component('v-icon', Icon)

const routes = [
  { path: '/', component: middle, meta: { transitionName: 'slide-down' } },
  //{ path: '/erfaring', component: left, meta: { transitionName: 'slide-right', side: 'left' } },
  //{ path: '/kontakt', component: right, meta: { transitionName: 'slide-left', side: 'right' } }
]

const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
